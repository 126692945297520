export enum KlosySVGId {
  klos = 'klos',
  klosyLogo = 'klosy-logo',
  logoCircle = 'logo-circle',
  slideMarker = 'slide-marker',
  chef210 = 'chef210',
  chef240 = 'chef240',
  santoku = 'santoku',
  filleting = 'filleting',
  serrated = 'serrated',
  peeling = 'peeling',
  lightAsianCleaver = 'lightAsianCleaver',
  cleaver = 'cleaver',
  yanagiba = 'yanagiba',
  boning = 'boning',
  cross = 'cross',
  punching = 'punching',
  klosSide = 'klos-side',
  infoSign = 'info-sign',
  crossCollapse = 'cross-collapse',
  arrow = 'arrow',
  arrowDown = 'arrow-down',
  logoMini = 'logo-mini',
  hamburger = 'hamburder',
  star = 'star',
  starEmpty = 'starEmpty',
  errorSign = 'errorSign',
  checked = 'checked',
  minus = 'minus',
  plus = 'plus',
  feblikLogo = 'feblik-logo',
  feblikBanner = 'feblik-banner',
}
